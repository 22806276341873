import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,6,7];

export const dictionary = {
		"/": [11],
		"/(common)/about": [12,[2]],
		"/(common)/auth/sign-in": [~13,[3]],
		"/(common)/auth/sign-up": [~14,[3]],
		"/(common)/auth/verify-email": [15,[3]],
		"/(content)/blog": [~23,[5]],
		"/(content)/blog/category/[category]": [~25,[5]],
		"/(content)/blog/[slug]": [~24,[5]],
		"/(common)/discover-preview/[slug]": [~16],
		"/(protected)/discover": [~26,[6]],
		"/(protected)/discover/property/[slug]": [~27,[6,7]],
		"/(protected)/discover/property/[slug]/pictures": [28,[6,7]],
		"/(common)/faq": [17,[2]],
		"/(protected)/kyb/create-business": [~29,[8]],
		"/(protected)/kyb/create-user": [~30,[8]],
		"/(protected)/kyb/resend": [~31,[8]],
		"/(protected)/kyb/send-kyb": [~32,[8]],
		"/(protected)/kyc": [~33],
		"/(protected)/kyc/resend": [~34],
		"/(common)/landing/[...catchall]": [~18,[2]],
		"/(common)/learn-more": [19,[2]],
		"/(common)/legal/[content]": [20,[2,4]],
		"/(protected)/my-homes": [~35,[6]],
		"/(protected)/order/[orderHashId]": [~36,[6,9]],
		"/(protected)/order/[orderHashId]/check-conditions": [~37,[6,9]],
		"/(protected)/order/[orderHashId]/signature": [~38,[6,9]],
		"/(protected)/portfolio": [~39,[6]],
		"/(common)/program/loyalty": [21,[2]],
		"/(common)/program/referral": [22,[2]],
		"/(protected)/set-phone": [~40],
		"/(protected)/settings": [~41,[6,10]],
		"/(protected)/settings/account": [~42,[6,10]],
		"/(protected)/settings/notifications": [~43,[6,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';